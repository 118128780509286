import React from "react";
import "./intro.css";
import backgroundImage from "../../assets/website_jake.jpg";
import softResume from ".././../assets/Josephs_Resume_Software.pdf";
import hardResume from ".././../assets/Joseph_s_Resume_Hardware.pdf";



const Intro = () => {
    return (
        <section id="intro">
            <div className="introContent">
                <span className="welcome">
                    Welcome,
                </span>
                <span className="introText">
                    I'm <span className="introName">Joseph Malegni</span>,
                    <br />
                    Computer Engineering
                    <br />
                    Student
                </span>
                <p className="introParagraph">
                    Currently studying at the University of Florida.
                    <br/>
                    Check out my software <a href={softResume} download="Malegni_Joseph_Software_Resume.pdf">resume </a>
                    or hardware <a href={hardResume} download="Malegni_Joseph_Hardware_Resume.pdf">resume</a>

                </p>
            </div>
            <img src={backgroundImage} alt="Joseph at Osaka Castle" className="bg" />
        </section>
    );
}

export default Intro;
