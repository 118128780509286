import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function BlogForm({ onSubmit }) {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ title, content });
        setTitle('');
        setContent('');
    };

    return (
        <div className="blog-post">
            <form onSubmit={handleSubmit} className="blogForm">
                <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Blog Title"
                    required
                />
                <ReactQuill
                    value={content}
                    onChange={setContent}
                    placeholder="Blog Content"
                />
                <button type="submit">Create Post</button>
            </form>
        </div>
    );
}

export default BlogForm;