import React from 'react';
import './skills.css';
import classImg from '../../assets/classes_section-removebg-preview.png';
import oscImg from '../../assets/oscImg.png';
import curProjImg from '../../assets/curProj-removebg-preview.png';

const skills = () => {
    return (
        <section id="skills">
            <span className="skillTitle">
                What I'm up to
            </span>
            <span className="skillDescript">
                Beginning my Fall semester classes, planning events for Open Source Club, and working on securing an internship for next summer
            </span>
            <div className="skillBars">
                <div className="skillBar">
                    <img src={classImg} alt="Classes" className="skillBarImg" />{/*credit: betka82 https://stock.adobe.com/contributor/205907078/betka82?load_type=author&prev_url=detail*/}
                    <div className="skillBarText">
                        <h2>Classes I am Taking:</h2>
                        <p>In the start of my third year, My primary classes are: Microprocessor Applications 2 and Circuits 1. I am very excited for these as they will open the doors to building pretty much any embedded device I could want. <a href="https://uflorida-my.sharepoint.com/:x:/g/personal/jmalegni_ufl_edu/EYvd1Cu0gQBMizKnCHzgBUkBMlHZY2nMw6XOSvVNVNgJhA?e=bI5Uzf" target="_blank" rel="noopener noreferrer">Click for a full list of the courses I have taken and plan to take for my undergrad degree.</a></p>
                    </div>
                </div>
                <div className="skillBar">
                    <img src={oscImg} alt="Open-Source Club" className="skillBarImg" />
                    <div className="skillBarText">
                        <h2>Open-Source Club:</h2>
                        <p>Last semester I was elected Vice President of the Open-Source Club at UF. The club hosts various projects that students are free to join and contribute to. As of this moment, along with my Vice-President duties, I am leading development on a visual novel game. <a href="https://ufosc.org/  " target="_blank" rel="noopener noreferrer">Click to visit the OSC Website.</a></p>
                    </div>
                </div>
                <div className="skillBar">
                    <img src={curProjImg} alt="Current Project" className="skillBarImg" />
                    <div className="skillBarText">
                        <h2>Latest Personal Project:</h2>
                        <p>Currently, I am working on building a custom gaming mouse that uses the SPI protocol to
                            communicate with my PC. Outside of that, much to my family's protest, I have been attempting
                            to set up a DNS Sinkhole on the home WIFI to block ads. So far, I haven't had much success.
                            But I know I can get this working. <a href="https://github.com/JMalegni" target="_blank" rel="noopener noreferrer">Click to
                                view my GitHub portfolio.</a></p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default skills;
