import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase';
import NavBar from "./components/NavBar/NavBar";
import Intro from "./components/Intro/Intro";
import Skills from "./components/Skills/skills";
import Portfolio from "./components/Portfolio/portfolio";
import Blog from "./components/Blog/blog";
import Blog2 from "./components/Blog2/blog2";
import Contact from "./components/Contact/contact";
import Footer from "./components/Footer/footer";
import Login from "./components/login";
import Login2 from "./components/login2";

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsLoggedIn(!!user);
        });

        return () => unsubscribe();
    }, []);

    const handleLogout = () => {
        setIsLoggedIn(false);
    };

    // useEffect to handle hash scrolling when navigating back to home
    useEffect(() => {
        if (window.location.hash) {
            const id = window.location.hash.replace("#", "");
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, []);

    return (
        <Router>
            <div className="App">
                <NavBar isLoggedIn={isLoggedIn} onLogout={handleLogout} />
                <Routes>
                    <Route path="/" element={
                        <>
                            <Intro />
                            <Skills />
                            <Portfolio />
                            <Contact />
                        </>
                    } />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog2" element={<Blog2 />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/login2" element={<Login2 />} />
                </Routes>
            </div>
            <Footer />
        </Router>
    );
}

export default App;