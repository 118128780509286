import React, { useState, useEffect } from 'react';
import { collection, addDoc, query, orderBy, limit, startAfter, getDocs } from "firebase/firestore";
import { db, auth } from '../../firebase';
import BlogPost from './blogpost.js';
import BlogForm from './blogform.js';
import { Link } from 'react-router-dom';
import './blog.css';

function Blog() {
    const [posts, setPosts] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        fetchPosts();
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setIsLoggedIn(!!user);
        });
        return () => unsubscribe();
    }, []);

    const fetchPosts = async () => {
        try {
            const q = query(collection(db, "posts"), orderBy("createdAt", "desc"), limit(5));
            const querySnapshot = await getDocs(q);
            const fetchedPosts = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
            setPosts(fetchedPosts);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };

    const fetchMorePosts = async () => {
        if (!lastVisible) return;
        try {
            const q = query(collection(db, "posts"), orderBy("createdAt", "desc"), startAfter(lastVisible), limit(5));
            const querySnapshot = await getDocs(q);
            const fetchedPosts = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
            setPosts([...posts, ...fetchedPosts]);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        } catch (error) {
            console.error('Error fetching more posts:', error);
        }
    };

    const addPost = async (newPost) => {
        try {
            await addDoc(collection(db, "posts"), {
                ...newPost,
                author: auth.currentUser.email,
                createdAt: new Date().toISOString()
            });
            fetchPosts();
        } catch (error) {
            console.error('Error adding post:', error);
        }
    };

    const handleLogout = () => {
        auth.signOut().then(() => {
            setIsLoggedIn(false);
        }).catch((error) => {
            console.error('Error logging out:', error);
        });
    };

    return (
        <section id="blog">
            <span className="blogTitle">My Blog</span>
            <span className="blogDescript">Welcome to my blog where I share my thoughts and experiences.</span>
            {posts.map(post => (
                <BlogPost key={post.id} {...post} />
            ))}
            <button className="loadMoreBtn" onClick={fetchMorePosts}>Load More</button>
            {isLoggedIn ? (
                <>
                <BlogForm onSubmit={addPost}/>
                <button className="logoutBtn" onClick={handleLogout}>Logout</button>
                </>
                ) : (
                <p className="loginPrompt">
                    Admin login <Link to="/login"> here</Link>
                </p>
            )}
        </section>
    );
}

export default Blog;