import React, {useRef} from "react"
import "./contact.css"
import linkedIcon from "../../assets/linkedin.png"
import youIcon from "../../assets/youtube.png"
import instaIcon from "../../assets/github-mark-white.png"
import emailjs from '@emailjs/browser';

const Contact = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        // Check if enough time has passed since the last email
        const lastEmailTime = localStorage.getItem('lastEmailTime');
        const currentTime = new Date().getTime();
        const timeElapsed = currentTime - (lastEmailTime || 0);
        const delayInMilliseconds = 60000; // 1 minute delay

        if (timeElapsed < delayInMilliseconds) {
            alert(`Please wait ${Math.ceil((delayInMilliseconds - timeElapsed) / 1000)} seconds before sending another email.`);
            return;
        }

        emailjs.sendForm('service_hclotjr', 'template_p5821io', form.current, 'zjT6CfT24UDwQTQv5')
            .then((result) => {
                console.log(result.text);
                e.target.reset();
                alert("Email Sent!");

                // Store the current time as the last email sent time
                localStorage.setItem('lastEmailTime', currentTime.toString());
            }, (error) => {
                console.log(error.text);
            });
    };

    return(
        <section id="contact">
            <h2 className="contactTitle">
                Reach Out
            </h2>
            <span className="contactDescript">
                If you would like to contact me, send a message down below.
            </span>
            <form className="contactForm" ref={form} onSubmit={sendEmail}>
                <input type="text" className="contactName" placeholder="Your Name" name="from_name"/>
                <input type="email" className="contactEmail" placeholder="Your Email" name="from_email"/>
                <textarea className="contactMsg" name="message" rows='5' placeholder="Your Message"></textarea>

                <button className="submitBtn" type="submit" value="Send">
                    Submit
                </button>
            </form>
            <div className="extLinks">
                <a href="https://www.linkedin.com/in/jwmalegni/" target="_blank" rel="noopener noreferrer"
                   className="extLink">
                    <img src={linkedIcon} alt="LinkedIn"/>
                </a>
                <a href="https://www.youtube.com/@jakemalegni6536" target="_blank" rel="noopener noreferrer"
                   className="extLink">
                    <img src={youIcon} alt="YouTube"/>
                </a>
                <a href="https://github.com/JMalegni" target="_blank" rel="noopener noreferrer" className="extLink">
                    <img src={instaIcon} alt="GitHub"/>
                </a>
            </div>
        </section>
    );
}

export default Contact