import React from "react"
import { Link } from 'react-router-dom';
import "./footer.css"

const footer = () => {
    return (
        <footer className="footer">
            <p>
                Designed and developed by Joseph Malegni. Powered
                <Link to="/login2"> by </Link>
                React
            </p>
        </footer>
    );
}

export default footer