import React from 'react';
import './Popup.css';

const Popup = ({ project, onClose }) => {
    return (
        <div className="popup">
            <div className="popup-inner">
                <button className="close-btn" onClick={onClose}>Close</button>
                <h2>{project.name}</h2>
                {project.imageUrl2 && <img src={project.imageUrl2} alt={project.name} className="project-img"/>}
                <h3>Links:</h3>
                <p>
                    {project.links.map((link, index) => (
                        <li key={index}>
                            <a href={link.url} target="_blank" rel="noopener noreferrer">{link.text}</a>
                        </li>
                    ))}
                </p>
                <p className="project-description">{project.description}</p>
            </div>
        </div>
    );
}

export default Popup;
