import React, { useState } from "react";
import Popup from "../Projects/Popup";
import "./portfolio.css";
import projects from "../Projects/ProjectData";

const Portfolio = () => {
    const [selectedProject, setSelectedProject] = useState(null);

    const openPopup = (project) => {
        setSelectedProject(project);
    };

    const closePopup = () => {
        setSelectedProject(null);
    };

    return (
        <section id="portfolio">
            <h2 className="portfolioTitle">My projects</h2>
            <span className="portfolioDescript">
                They may not be world-changing yet, but I sure do take pride in them. Click on the images for more information.
            </span>
            <div className="portfolioGrid">
                {projects.map((project) => (
                    <div key={project.id} className="portfolioItem">
                        <img
                            src={project.imageUrl}
                            alt={project.name}
                            className="portfolioImg"
                            onClick={() => openPopup(project)}
                        />
                        <div className="imageTitle">{project.name}</div>
                    </div>
                ))}
            </div>

            {selectedProject && (
                <Popup
                    project={selectedProject}
                    onClose={closePopup}
                />
            )}
        </section>
    );
};

export default Portfolio;