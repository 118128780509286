import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import './NavBar.css';
import logo from "../../assets/JMLogo.png";
import msgBtn from "../../assets/contact.png";
import menu from "../../assets/menu.png";

const NavBar = ({ isLoggedIn, onLogout }) => {
    const [showMenu, setShowMenu] = useState(false);
    const location = useLocation();

    const handleNavClick = (id) => {
        if (location.pathname !== "/") {
            window.location.href = `/#${id}`;
        } else {
            document.getElementById(id).scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <nav className="NavBar">
            <img src={logo} alt="Logo" className="logo" />

            <div className="desktopMenu">
                <RouterLink to="/" className="desktopMenuListItem" onClick={() => handleNavClick('intro')}>
                    Home
                </RouterLink>
                <RouterLink to="/" className="desktopMenuListItem" onClick={() => handleNavClick('skills')}>
                    About
                </RouterLink>
                <RouterLink to="/" className="desktopMenuListItem" onClick={() => handleNavClick('portfolio')}>
                    Projects
                </RouterLink>
                <RouterLink to="/blog" className="desktopMenuListItem">Blog</RouterLink>

            </div>

            <button className="desktopMenuButton" onClick={() => handleNavClick('contact')}>
                <img src={msgBtn} alt="Message Button" className="desktopMenuIcon" />
                Message Me
            </button>

            <img src={menu} alt="Menu" className="mobMenu" onClick={() => setShowMenu(!showMenu)} />

            <div className="navMenu" style={{display: showMenu ? 'flex' : 'none'}}>
                <RouterLink to="/" className="listItem" onClick={() => handleNavClick('intro')}>
                    Home
                </RouterLink>
                <RouterLink to="/" className="listItem" onClick={() => handleNavClick('skills')}>
                    About
                </RouterLink>
                <RouterLink to="/" className="listItem" onClick={() => handleNavClick('portfolio')}>
                    Projects
                </RouterLink>
                <RouterLink to="/blog" className="listItem">Blog</RouterLink>
                <RouterLink to="/" className="listItem" onClick={() => handleNavClick('contact')}>
                    Message Me
                </RouterLink>

            </div>
        </nav>
    );
};

export default NavBar;