import React from 'react';
import { format } from 'date-fns';

function BlogPost({ title, content, author, createdAt }) {
    return (
        <article className="blog-post">
            <h3>{title}</h3>
            <div className="post-meta">
                <span>By: {author}</span>
                <time dateTime={createdAt}>
                    {format(new Date(createdAt), 'MMMM d, yyyy')}
                </time>
            </div>
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </article>
    );
}

export default BlogPost;