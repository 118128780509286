import React, { useState, useEffect } from 'react';
import { collection, addDoc, query, orderBy, limit, startAfter, getDocs } from "firebase/firestore";
import { db, auth } from '../../firebase';
import Blogpost2 from './blogpost2.js';
import Blogform2 from './blogform2.js';
import './blog2.css';

function Blog2() {
    const [posts, setPosts] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        fetchPosts();
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setIsLoggedIn(!!user);
        });
        return () => unsubscribe();
    }, []);

    const fetchPosts = async () => {
        try {
            const q = query(collection(db, "posts2"), orderBy("createdAt", "desc"), limit(5));
            const querySnapshot = await getDocs(q);
            const fetchedPosts = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
            setPosts(fetchedPosts);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };

    const fetchMorePosts = async () => {
        if (!lastVisible) return;
        try {
            const q = query(collection(db, "posts2"), orderBy("createdAt", "desc"), startAfter(lastVisible), limit(5));
            const querySnapshot = await getDocs(q);
            const fetchedPosts = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
            setPosts([...posts, ...fetchedPosts]);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        } catch (error) {
            console.error('Error fetching more posts:', error);
        }
    };

    const addPost = async (newPost) => {
        try {
            await addDoc(collection(db, "posts2"), {
                ...newPost,
                author: auth.currentUser.email,
                createdAt: new Date().toISOString()
            });
            fetchPosts();
        } catch (error) {
            console.error('Error adding post:', error);
        }
    };

    const handleLogout = () => {
        auth.signOut().then(() => {
            setIsLoggedIn(false);
        }).catch((error) => {
            console.error('Error logging out:', error);
        });
    };

    return (
        <section id="blog">
            {isLoggedIn ? (
                <>
                    <span className="blogTitle">Private Blog</span>
                    <span className="blogDescript">If you aren't Jake you shouldn't be here.</span>
                    {posts.map(post => (
                        <Blogpost2 key={post.id} {...post} />
                    ))}
                    <button className="loadMoreBtn" onClick={fetchMorePosts}>Load More</button>
                    <Blogform2 onSubmit={addPost} />
                    <button className="logoutBtn" onClick={handleLogout}>Logout</button>
                </>
            ) : (
                <p className="loginPrompt">
                    404 Not Found
                </p>
            )}
        </section>
    );
}

export default Blog2;
